import React, { useEffect, useState } from 'react';
import '../css/commingsoon.css'; // Keep your custom CSS import

export default function CommingSoon() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`coming-soon-container ${loaded ? 'loaded' : ''}`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/background-image.jpg')`, // Background image with dark gradient overlay
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'grayscale(100%)', // Apply grayscale only to background
      }}
    >
      <div className="coming-soon-content">
        <img
          src="/images/logo.png" // Ensure logo is accessed from the public/images folder
          alt="Logo"
          className="logo"
        />
        <h1 className="launching-soon-text">Launching Soon</h1>
      </div>
    </div>
  );
}
