import React from 'react';
import CommingSoon from './pages/commingsoon'; // Import the Coming Soon page

function App() {
  return (
    <div className="App">
      {/* Render the Coming Soon page */}
      <CommingSoon />
    </div>
  );
}

export default App;
